import React from 'react';
import YouTube from 'react-youtube';
import './suggestions.css';

class LiveAndSuggestions extends React.Component {
  render() {
    const opts = {
      width: '600px',
      playerVars: {
        controls: 2,
        modestbranding: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
      },
    };

    return (
      <div className="live-suggestion">
        <YouTube className="youtube" videoId="B73nmYZW3ss" opts={opts} onReady={this._onReady} />
        <SuggestionBox />
      </div>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

function SuggestionBox() {
  return (
    <div className="suggestion-box">
      <h2>Sugestões</h2>
      <ul>
        {[...Array(5).keys()].map(num => (
          <li className="suggestion-item" key={num}>
            {num + 1}. Sugestão {num + 1}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LiveAndSuggestions;