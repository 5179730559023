// components/Banner.js
import React from 'react';
import logo from '../assets/images/banner-getup.png';
import './banner.css';

function Banner() {
    return (
        <div className="radio-banner">
            <img src={logo} alt="Webradio XYZ" />
        </div>
    );
}

export default Banner;
