// Navbar.js
import React, { useState } from 'react';
import logo from '../assets/images/getup-logo.png';
import './navbar.css'
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from '../Firebase/firebaseConfig'; // Importe o serviço auth


const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user);  // Logue o usuário retornado para ver todos os detalhes disponíveis.
    } catch (error) {
      console.error("Erro ao fazer login:", error.message);
    }
  }
  

const Navbar = () => {
    const [chatVisible, setChatVisible] = useState(false);

    return (
        
        <nav className="navbar">
            <div className="nav-content">
                <div className="radio-logo">
                    <img src={logo} alt="Logo" />
                </div>
                
                <div className="menu-items">
                    <a href="#home">Início</a>
                    <a href="#about">Sobre</a>
                    <a href="#contact">Contato</a>
                </div>
                
                <div className="player-container">
                    <AudioPlayer
                        src="https://hts02.kshost.com.br:12050/live"
                        showJumpControls={false}
                        customAdditionalControls={[]}
                        customVolumeControls={[RHAP_UI.VOLUME]}
                        style={{ width: '300px' }}
                    />
                </div>

                <button className="live-chat-button" onClick={() => setChatVisible(!chatVisible)}>Chat Ao Vivo</button>
                {chatVisible && <div className="chat-window">Conteúdo do chat</div>}
                <button className="entrar-usuario" onClick={handleGoogleLogin}><i class="fi fi-rs-users"></i></button>

                <i className="fi fi-rr-user"></i>
            </div>
        </nav>
    );
};

export default Navbar;
